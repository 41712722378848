import React from "react"
import { CLOUDINARY_URL } from "../constants"

const PageHeader = ({
  button,
  postType,
  children,
  className,
  style,
  heroImage,
  color,
}) => (
  <section
    id="header"
    className={`layout__header${className ? ` ${className}` : ""}`}
    style={style ? style : null}
  >
    {heroImage && heroImage.length > 0 && (
      <div
        className="layout__header-background"
        style={{
          background: `${color ? color : null}`,
        }}
      >
        <img
          src={`${CLOUDINARY_URL}/${heroImage[0].filename}`}
          alt={heroImage[0].title}
        />
      </div>
    )}

    <header className="layout__header-block heading">
      {button ? button : null}
      {postType ? postType : null}
      <div className="layout__inner layout__inner--full-width">{children}</div>
    </header>
  </section>
)

export default PageHeader
