import React, { useState, useEffect } from "react"
import { Link, withPrefix, graphql } from "gatsby"
import { CLOUDINARY_URL } from "../constants"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import PageHeader from "../components/pageHeader"

import "./work.scss"

const WorkPage = ({ data, location }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
    clearInterval()
  }, [])
  const {
    workPageContent: { title, subtitle },
    caseStudies,
  } = data.craft
  return (
    <Layout location={location}>
      <SEO title="Our Work" location={location} />
      <PageHeader
        className={`work__header ${loaded && "loaded"}`}
        style={{
          backgroundImage: `url(${withPrefix("/images/work/bkgd_work.png")})`,
        }}
      >
        <>
          <h1>{title}</h1>
          <div className="heading__subtext--grid">
            <div className="heading__subtext heading__subtext--bordered-left">
              <h2>{subtitle}</h2>
            </div>
            <div className="heading__subtext heading__subtext--bordered work-categories">
              <div className="work-categories__inner">
                <h2>Explore our work in</h2>
                <h2>
                  Transportation, Education, Healthcare, Travel and Leisure and
                  Social Innovation.
                </h2>
              </div>
            </div>
          </div>
        </>
      </PageHeader>
      <div className="layout__section work-grid">
        {caseStudies.length > 0 && (
          <>
            {caseStudies.map((caseStudy, index) => (
              <React.Fragment key={caseStudy.id}>
                {index >= 1 && index < caseStudies.length && <hr />}
                <div
                  className={`content-grid ${
                    index % 2 === 0 ? "" : "flipped"
                  } case-studies__grid`}
                >
                  <div className="case-studies__image">
                    <img
                      src={`${CLOUDINARY_URL}/x_${Math.floor(
                        caseStudy.heroImage[0].focalPoint.x *
                          caseStudy.heroImage[0].width *
                          (225 / caseStudy.heroImage[0].height)
                      )},y_${Math.floor(
                        caseStudy.heroImage[0].focalPoint.y *
                          caseStudy.heroImage[0].height *
                          (225 / caseStudy.heroImage[0].height)
                      )}/${caseStudy.heroImage[0].filename}`}
                      alt=""
                    />
                  </div>
                  <div className="case-studies__description">
                    <h2 className="study-type">{caseStudy.caseStudyType}</h2>
                    <h2 className="study-title font--larger">
                      {caseStudy.title}
                    </h2>
                    <p className="study-description">{caseStudy.subtitle}</p>
                    <Link to={`/work/${caseStudy.slug}`} className="btn">
                      Learn more
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </Layout>
  )
}

export const getWorkPageContentQuery = graphql`
  query getWorkPageContentQuery {
    craft {
      workPageContent: entry(section: work) {
        ... on Craft_Work {
          id
          slug
          title
          subtitle
        }
      }
      caseStudies: entries(section: [caseStudy]) {
        ... on Craft_CaseStudy {
          id
          slug
          title
          subtitle
          caseStudyType
          heroImage {
            ... on Craft_HeroVolume {
              id
              filename
              focalPoint {
                x
                y
              }
              height
              width
              heroImageSize_FieldData {
                selected {
                  value
                }
              }
              heroImageSizeModifier_FieldData {
                selected {
                  label
                }
              }
              title
            }
          }
        }
      }
    }
  }
`

export default WorkPage
